
export const TextDisplayLarge = ({text, color, children}) => {
    return <span className={`md:ont-[700] md:text-[54px] md:leading-[64px] font-[600] text-[36px] leading-[44px] text-${color} font-sans`}>{text}{children}</span>
};

export const TextDisplayMedium = ({text, color, children}) => {
    return <span className={`md:font-[600] md:text-[40px] md:leading-[52px] font-[600] text-[32px] leading-[40px] text-${color} font-sans`}>{text}{children}</span>
};

export const TextDisplaySmall = ({text, color, children}) => {
    return <span className={`md:font-[600] md:text-[36px] md:leading-[44px] font-[600] text-[28px] leading-[36px] text-${color} font-sans`}>{text}{children}</span>
};

export const TextHeadlineLarge = ({text, color, children}) => {
    return <span className={`md:font-[600] md:text-[32px] md:leading-[40px] font-[600] text-[20px] leading-[32px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextHeadlineMedium = ({text, color, children}) => {
    return <span className={`md:font-[600] md:text-[28px] md:leading-[36px] font-[600] text-[24px] leading-[32px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextHeadlineSmall = ({text, color, children}) => {
    return <span className={`md:font-[600] md:text-[24px] md:leading-[32px] font-[500] text-[20px] leading-[28px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextTitleLerge = ({text, color, children}) => {
    return <span className={`md:font-[500] md:text-[20px] md:leading-[28px] font-[500] text-[16px] leading-[24px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextTitleMedium = ({text, color, children}) => {
    return <span className={`font-[500] text-[16px] leading-[24px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextTitleSmall = ({text, color, children}) => {
    return <span className={`font-[500] text-[14px] leading-[20px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextBodyXLarge = ({text, color, children}) => {
    return <span className={`md:font-[400] md:text-[20px] md:leading-[28px] font-[400] text-[14px] leading-[20px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextBodyLarge = ({text, color, children}) => {
    return <span className={`md:font-[400] md:text-[16px] md:leading-[24px] font-[400] text-[14px] leading-[20px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextBodyMedium = ({text, color, children}) => {
    return <span className={`font-[400] text-[14px] leading-[20px] text-${color} font-sans`}>{text}{children}</span>

}

export const TextBodySmall = ({text, color, children}) => {
    return <span className={`font-[400] text-[12px] leading-[16px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextLabelLarge = ({text, color, children}) => {
    return <span className={`font-[500] text-[14px] leading-[20px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextLabelMedium = ({text, color, children}) => {
    return <span className={`font-[500] text-[12px] leading-[16px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextLabelSmall = ({text, color, children}) => {
    return <span className={`font-[500] text-[11px] leading-[16px] text-${color} font-sans`}>{text}{children}</span>
}

export const TextLabelLargeProminent = ({text, color, children}) => {
    return <span className={`text-sm leading-5 font-semibold text-${color} font-sans`}>{text}{children}</span>
}