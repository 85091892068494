import Head from "next/head";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Sdk from "casdoor-js-sdk";
import { useGlobalContext } from "../context/GlobalContext";
import { getCasdoorConfig, getPkceSdk } from "../socket/socket.js";
import localStorageSlim from 'localstorage-slim';
import Desktop from "../components/landingPage/index.js";
import Loader from "../components/agreementGeneration/Loader.js";

const Home = () => {
  const { casdoorConfig, setCasdoorConfig } = useGlobalContext();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [pkceSDK, setPkceSDK] = useState(null)
  

  useEffect(() => {
    const token = localStorageSlim.get('token', { decrypt: true });
    if (token) {
      router.push('/dashboard');
    } else {
      setLoading(false);
      getCasdoorConfig(setCasdoorConfig);
    }
  }, []);


  useEffect(() => {
    if(casdoorConfig){
      let pkce = getPkceSdk(casdoorConfig)
      setPkceSDK(pkce)
    }
  }, [casdoorConfig]);

  const login = () => {
    window.location.replace(pkceSDK.authorizeUrl())
  }

  const signup = () => {
    const signUpUrl = pkceSDK.authorizeUrl().replace('/login', '/signup')
    window.location.replace(signUpUrl)
  }


  if (loading) {
    return <Loader loadingMessage="Loading..." />;
  }

  return (
    <>
      <Head>
        <title>Welcome</title>
      </Head>
      <Desktop login={login} signup={signup}/>
    </>
  );
}

export default Home;


// pages/index.js
// import dynamic from 'next/dynamic';

// const PDFEditor = dynamic(() => import('../components/test/PDFEditor'), { ssr: false });

// export default function Home() {
//   return (
//     <div>
//       <h1>Edit PDF Form</h1>
//       <PDFEditor />
//     </div>
//   );
// }
