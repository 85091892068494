const env = {
    domainUrl: process.env.NEXT_PUBLIC_DOMAIN_URL,
    authDomainUrl: process.env.NEXT_PUBLIC_AUTH_DOMAIN_URL,
    s3RealtorUrl: process.env.NEXT_PUBLIC_S3_REALTOR_URL,
    clusterEnv: process.env.NEXT_PUBLIC_NODE_ENV,
    googleAnalyticsID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
    socketUrl: process.env.NEXT_PUBLIC_SOCKET_URL,
    orgName: process.env.NEXT_PUBLIC_ORG_NAME,
    ttlExpiryInHours: process.env.NEXT_PUBLIC_TTL_EXPIRY_IN_HOURS
}

export default env