import { TextBodyLarge, TextLabelLarge } from '../common/typography';
import styles from './loader.module.css';  // Import the modular CSS file

const Loader = ({ loadingMessage }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 lg:backdrop-blur-sm ">
      <div className={`flex flex-col items-center justify-center ${styles.animateFadeIn}`}>
        <div className={`${styles.loaderSpinner} h-12 w-12 md:h-16 md:w-16`}></div>
        <p className="font-bold mt-4 text-base md:text-lg text-white">
          <TextBodyLarge>
              {loadingMessage}
          </TextBodyLarge>
        </p>
      </div>
    </div>
  );
};

export default Loader;
